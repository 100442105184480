<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('subscribe.mp') }}</div>
                <el-button type="primary" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
            </div>
        </el-card>
        <el-card class="mb-50" v-loading="loading">
            <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-12">
                <el-col :span='2'>Logo / {{ $t('name') }}</el-col>
                <el-col :span='2'>{{ $t('quantity') }}</el-col>
                <el-col :span='4'>{{ $t('subscribe.cost_or_value') }} (USDT)</el-col>
                <el-col :span='4'>{{ $t('subscribe.cost_or_price') }} (USDT)</el-col>
                <el-col :span='3'>{{ $t('subscribe.Profit') }} (USDT)</el-col>
                <el-col :span='3'>{{ $t('order_no') }}</el-col>
                <el-col :span='3'>{{ $t('date_time') }}</el-col>
                <el-col :span='3' class="text-right">{{ $t('status') }}</el-col>
            </el-row>
            <div v-if="list.data.length">
                <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                    <el-col :span='2'>
                        <div class="d-flex-center">
                            <el-avatar class="mr-10" shape="square" :size="28" :src="item.logo" />
                            <span class="text-center mr-auto font-16">{{ item.coin }}</span>
                        </div>
                    </el-col>
                    <el-col :span='2'>{{ item.amount }}</el-col>
                    <el-col :span='4'>{{ item.total }} <i class="el-icon-right"></i> {{ item.value }}</el-col>
                    <el-col :span='4'>{{ item.price }} <i class="el-icon-right"></i> {{ item.now_price }}</el-col>
                    <el-col :span='3'>{{ item.profit }} ({{ item.profit_rate }}%)</el-col>
                    <el-col :span='3' class="font-12">{{ item.order_id }}</el-col>
                    <el-col :span='3' class="font-12">{{ item.create_time }}</el-col>
                    <el-col :span='3' class="text-right">
                        <el-tag v-if="item.state == 2" type="warning" effect="plain" hit>{{ $t('subscribe.locking_to') }} {{ item.thaw_date }}</el-tag>
                        <el-tag v-else-if="item.state == 3" type="primary" effect="plain" hit>{{ $t('subscribe.atu') }}</el-tag>
                        <el-tag v-else type="success" effect="plain" hit>{{ $t('subscribe.finished') }}</el-tag>
                    </el-col>
                </el-row>
                <div class="d-flex-center pt-20">
                    <el-pagination
                        :hide-on-single-page="true"
                        background
                        layout="prev, pager, next"
                        :page-size="list.per_page"
                        :current-page="list.current_page"
                        :total="list.total"
                        @current-change="onPage"
                    />
                </div>
            </div>
            <el-empty v-else :image-size="100" description="No Data"></el-empty>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: [
                    // {
                    //     id: 1,
                    //     order_id: '124512458451454',
                    //     logo: '/logi.png',
                    //     coin: 'WPS',
                    //     price: '0.12451',
                    //     amount: '100000.00',
                    //     order_amount: '100000.00',
                    //     total: '112541',
                    //     state: 2,
                    //     create_time: '2024-06-12 12:24:15'
                    // }
                ]
            },
            status: [
                ['primary', 'Frozen'],
                ['success', 'Thaw']
            ]
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            this.loading = true
            try {
                const { data } = await this.$request.post('coin_buy_log/positions_pc', this.query)
                this.list = data
            } catch (error) {
                //
            }
            this.loading = false
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>